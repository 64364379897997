<!-- 录入学员考试成绩 -->
<template>
  <div class="TestResult">
    <!-- 查询表单 -->
    <el-form ref="form" :model="form" inline class="form" label-width="120px">
      <el-form-item label="考试日期">
        <el-date-picker
          v-model="form.testDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学科名称">
        <el-select
          v-model="form.subject"
          placeholder="请选择学科"
          :clearable="true"
        >
          <el-option
            v-for="item in form.subjectOpt"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择机构">
        <el-select
          v-model="form.mechanism"
          filterable
          placeholder="请选择机构"
          :clearable="true"
        >
          <el-option
            v-for="item in form.mechanismOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单据状态">
        <el-select
          v-model="form.condition"
          placeholder="请选择单据状态"
          :clearable="true"
        >
          <el-option
            v-for="item in form.conditionChoose"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
      </el-form-item>
    </el-form>
    <!-- 按钮功能部分 -->
    <div class="" style="display: flex; justify-content: space-between">
      <div class="" style="margin-left: 22px">
        <el-button
          size="small"
          icon="el-icon-refresh"
          style="background-color: #2c3e50; border-radius: 3px"
          @click="refresh"
        >
          <!-- 刷新 -->
        </el-button>
        <el-button
          size="small"
          icon="el-icon-plus"
          style="background: #18bc9c; color: #ffffff"
          @click="newlyAdded"
        >
          新增
        </el-button>
        <button class="print" @click="print">打印预览</button>
        <button class="derive" @click="derive">导出到Excel</button>
        <button class="more" @click="more">更多</button>
        <el-button
          size="small"
          icon="el-icon-edit"
          style="background: #18bc9c; color: #ffffff"
          @click="batch"
        >
          批量考试成绩
        </el-button>
      </div>
      <div class="" style="margin-right: 10px">
        <el-button
          size="small"
          style="background: #18bc9c; color: #ffffff"
          @click="query"
        >
          查询
        </el-button>
        <el-button
          size="small"
          style="background: #fafafa; color: #666666"
          @click="Reset"
        >
          重置
        </el-button>
      </div>
    </div>
    <!-- 批量考试成绩 -->
    <el-dialog
      width="400px"
      :visible.sync="dlog"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form
        ref="form1"
        :model="form1"
        :rules="rules"
        inline
        class="form1"
        label-width="80px"
      >
        <el-form-item label="成绩选择" prop="adopts">
          <el-select
            v-model="form1.adopts"
            filterable
            placeholder="请选择"
            :clearable="true"
          >
            <el-option
              v-for="item in form1.adoptsOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm1('form1')">取 消</el-button>
        <el-button
          style="background: #18bc9c; color: #fff"
          @click="submitform1('form1')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      @selection-change="checkAll"
      style="width: 97.9%; flex: 1"
      height="650"
      id="out-table"
      @sort-change="abc"
    >
      <!-- 复选按钮 -->
      <el-table-column type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column
        prop="status"
        sortable="custom"
        label="单据状态"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0 || scope.row.status == ''"></span>
          <span v-else-if="scope.row.status == 1">草稿</span>
          <span v-else-if="scope.row.status == 2">报考</span>
          <span v-else-if="scope.row.status == 3">准考</span>
          <span v-else-if="scope.row.status == 4">考试OK</span>
          <span v-else-if="scope.row.status == 5">取消</span>
          <span v-else-if="scope.row.status == 6">退款取消</span>
          <span v-else-if="scope.row.status == 7">延期</span>
          <span v-else-if="scope.row.status == 8">缺考</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_receipt"
        sortable="custom"
        label="机构收款"
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_receipt == 0">未收款</span>
          <span v-else-if="scope.row.is_receipt == 1">已收款</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_transfer"
        sortable="custom"
        label="过账状态"
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_transfer == 0">未过账</span>
          <span v-else-if="scope.row.is_transfer == 1">已过账</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_deputy"
        sortable="custom"
        label="是否副证"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_deputy == 0">否</span>
          <span v-else-if="scope.row.is_deputy == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="enroll_number"
        sortable="custom"
        label="报名单号"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="certificate_no"
        sortable="custom"
        label="准考证号"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        sortable="custom"
        label="学生姓名"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="idcard"
        sortable="custom"
        label="身份证"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column prop="head_image" label="相片" align="center" width="80">
        <template slot-scope="scope">
          <img
            :src="scope.row.head_image"
            style="width: 15px; height: 20px; margin-top: 5px"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        sortable="custom"
        label="联系电话"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="teacher"
        sortable="custom"
        label="指导教师"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable="custom"
        label="提供机构"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="course"
        sortable="custom"
        label="考试科目"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="original_level"
        sortable="custom"
        label="原等级"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="exam_level"
        sortable="custom"
        label="等级"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="exam_date"
        sortable="custom"
        label="报名日期"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="apply"
        sortable="custom"
        label="申请考试大类"
        align="center"
        width="230"
      >
      </el-table-column>
      <el-table-column
        prop="exam2_date"
        sortable="custom"
        label="考试日期"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_startdate"
        sortable="custom"
        label="开始时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_enddate"
        sortable="custom"
        label="结束时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_duration"
        sortable="custom"
        label="时长(hr)"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="enroll_price"
        sortable="custom"
        label="报名费(元)"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="manage_price"
        sortable="custom"
        label="考级管理费(元)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="certificatecopy_price"
        sortable="custom"
        label="证书费(元)"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="price1"
        sortable="custom"
        label="应收金额(元)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="price2"
        sortable="custom"
        label="实收金额"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="difference"
        sortable="custom"
        label="差额"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="order_name"
        sortable="custom"
        label="下单人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        sortable="custom"
        label="考试备注"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <!--  <el-table-column prop="text_score" sortable="custom" label="成绩" align="center" width="100">
            </el-table-column> -->
      <el-table-column
        prop="exam_result"
        sortable="custom"
        label="考试结论"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.exam_result == 0 || scope.row.exam_result == ''"
          ></span>
          <span v-else-if="scope.row.exam_result == 1">未录入</span>
          <span v-else-if="scope.row.exam_result == 2">通过</span>
          <span v-else-if="scope.row.exam_result == 3">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="exam_result"
        sortable="custom"
        label="成绩等级"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.exam_result == 1">-</span>
          <span v-else-if="scope.row.exam_result == 2">
            {{ scope.row.exam_level }}
          </span>
          <span v-else-if="scope.row.exam_result == 3">
            {{ scope.row.original_level }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="birthday"
        sortable="custom"
        label="出生日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="age"
        sortable="custom"
        label="报考时年龄"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="area_address"
        sortable="custom"
        label="场地"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="add_name"
        sortable="custom"
        label="录入人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="add_time"
        sortable="custom"
        label="录入时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="update_name"
        sortable="custom"
        label="修改人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="update_time"
        sortable="custom"
        label="最近修改时间"
        align="center"
        width="230"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            class="checkBtn"
            icon="el-icon-view"
            size="mini"
            @click="details(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            class="edit"
            icon="el-icon-edit"
            size="mini"
            @click="edit(scope.$index, scope.row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部 -->
    <div class="" style="display: flex; justify-content: space-between">
      <div class="" style="margin-left: 22px">
        <div class="txtbox">
          报名人数
          <input
            type="text"
            class="itp"
            :value="peopleNum"
            disabled="disabled"
          />
        </div>
        <div class="txtbox" style="margin-left: 10px">
          报名费合计
          <input
            type="text"
            class="itp"
            :value="totalize"
            disabled="disabled"
          />
        </div>
      </div>
      <!--分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200, 500, 1000]"
        :page-size="10"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 打印预览弹出框 -->
    <el-dialog width="860px" :visible.sync="dialog">
      <div id="printTest">
        <!-- 头部 -->
        <div class="top">
          <div class="top-logo">
            <img :src="printData.top_logo" />
          </div>
          <div class="top-title">社会美术水平考级中心全国美术等级考试</div>
        </div>
        <!-- 报名表 -->
        <div class="sign_up">报名表</div>
        <!-- 编号 -->
        <div class="" style="text-align: right">
          <div class="" style="margin-right: 40px; font-size: 16px">
            No:<span style="padding-left: 10px">TRF.2111300014</span>
          </div>
        </div>
        <!-- 表格 -->
        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td class="td_1" rowspan="5"><img :src="printData.head_img" /></td>
            <td>所属地区：</td>
            <td>深圳书画艺术学院考点</td>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>{{ printData.name }}</td>
            <td class="td_2">性别</td>
            <td class="td_3">{{ printData.sex }}</td>
          </tr>
          <tr>
            <td>拼音名</td>
            <td>{{ printData.pinyin }}</td>
            <td class="td_2">国籍</td>
            <td class="td_3">{{ printData.nationality }}</td>
          </tr>
          <tr>
            <td>报考专业</td>
            <td>{{ printData.major }}</td>
            <td class="td_2">民族</td>
            <td class="td_3">{{ printData.nation }}</td>
          </tr>
          <tr>
            <td>原有级别</td>
            <td>{{ printData.original_grade }}</td>
            <td class="td_2">现报级别</td>
            <td class="td_3">{{ printData.level }}</td>
          </tr>
          <tr>
            <td>家庭住址</td>
            <td colspan="2">{{ printData.address }}</td>
            <td class="td_2">出生日期</td>
            <td class="td_3">{{ printData.birthday }}</td>
          </tr>
          <tr>
            <td>手机号码</td>
            <td colspan="2">{{ printData.phone }}</td>
            <td class="td_2">邮编</td>
            <td class="td_3">{{ printData.Zipcode }}</td>
          </tr>
          <tr>
            <td>考生证件号</td>
            <td colspan="2">{{ printData.idcard }}</td>
            <td class="td_2">电话</td>
            <td class="td_3">{{ printData.telephone }}</td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="4" class="remarks">
              一、本表系录入全国社会美术考级数据库及考生等级证书的依据，请考生务必用正楷字如实填写。因填表错误而造成证书重做，考生必须负担证书重做的工本费。<br />
              二、一经报名，不得退考、更改考试科目、报考级别。<br />
              三、因考生本人原因缺考视为自动弃权，恕不退费和补考。
            </td>
          </tr>
        </table>
        <!-- 打印预览底部 -->
        <div class="bottom">
          <!-- 考点经办人(签章) -->
          <div class="handle">考点经办人(签章)：<input type="text" /></div>
          <div class="bottom_text">
            <p>中国美术学院社会美术水平考级中心</p>
            <p>深圳书画艺术学院考级办公室</p>
          </div>
          <div class="phone_date">
            <span>电话：0755-8883 0206</span>
            <span>{{ printData.specificDate }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button
          style="background: #18bc9c; color: #fff"
          v-print="'#printTest'"
          >打 印</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        testDate: "", //考试日期
        subject: "", //学科名称
        subjectOpt: [], //学科名称选项
        mechanism: "", //选择机构
        mechanismOpt: [], //选择机构选项
        search: "", // 搜索
        condition: "", //单据状态
        conditionChoose: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "草稿",
          },
          {
            value: 2,
            label: "报考",
          },
          {
            value: 3,
            label: "准考",
          },
          {
            value: 4,
            label: "考试OK",
          },
          {
            value: 5,
            label: "取消",
          },
          {
            value: 6,
            label: "退款取消",
          },
          {
            value: 7,
            label: "延期",
          },
          {
            value: 8,
            label: "缺考",
          },
        ], //单据状态选择
        exam: "", //考试大类
        examOpt: [], //考试大类选项
      },
      tableData: [],
      currentPage: 1,
      currentLimit: 20, //条数
      total: 0,
      peopleNum: "", //报名人数
      totalize: "", //报名费合计
      ids: [],
      dialog: false, //判断打印预览开关
      printData: {
        top_logo: require("../../assets/s-logo.png"), //logo
        number: "", //编号
        head_img: "", //头像
        region: "", //所属地区
        name: "", //姓名
        sex: "", //性别
        pinyin: "", //拼音名
        nationality: "", //国籍
        major: "", //报考专业
        original_grade: "", //原有级别
        level: "", //现报等级
        address: "", //家庭住址
        birthday: "", //出生日期
        phone: "", //手机号码
        idcard: "", //考试证件号
        Zipcode: "", //邮编
        telephone: "", //电话
        remarks: "", //备注
        specificDate: "", //日期
      },
      arr: [],
      dlog: false,
      form1: {
        adopts: "",
        adoptsOpt: [
          { value: 1, label: "通过" },
          { value: 2, label: "不通过" },
        ],
      },
      rules: {
        adopts: [
          { required: true, message: "考试成绩不能为空", trigger: "change" },
        ],
      },
      id: "",
      bool: false,
    };
  },
  created() {
    // 学科数据
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.subjectOpt = res.data.list;
      }
    });

    // 选择机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.form.mechanismOpt = res.data.list;
    });
    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form.examOpt = res.data.list;
      }
    });

    this.getList();
  },
  activated() {
    this.getList();
    // console.log("我是缓存")
  },
  methods: {
    getList() {
      this.$request({
        url: "/api/examenroll/list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
          dateQuery: this.form.testDate, //查询时间
          search: this.form.search, //关键字
          status: this.form.condition, //单据状态
          course_id: this.form.subject, //学科ID
          org_id: this.form.mechanism, //机构id
          apply_category: this.form.exam,
          sort: this.arr,
        },
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.peopleNum = res.data.count;
        this.totalize = res.data.sum_price;
      });
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    query() {
      //查询
      this.currentPage = 1;
      this.getList();
    },
    Reset() {
      //重置
      this.form.testDate = ""; //查询时间
      this.form.subject = ""; //学科名称id
      this.form.search = ""; // 搜索
      this.form.mechanism = ""; //机构id
      this.form.exam = "";
      this.getList();
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    newlyAdded() {
      //新增
      this.$router.push({
        path: "addTestResult",
      });
    },
    more() {
      //更多
      this.$router.push({
        path: "testResultMore",
      });
    },
    edit(index, row) {
      //编辑
      this.$router.push({
        path: "editTestResult",
        query: {
          id: row.id,
        },
      });
    },
    details(index, row) {
      //详情
      this.$router.push({
        path: "testResultDetails",
        query: {
          id: row.id,
        },
      });
    },
    checkAll(selection) {
      //获取批量收款id
      console.log(selection);
      this.ids = [];
      this.id = "";
      selection.forEach((item) => {
        this.ids.push(item.id);
        this.id = item.id;
      });
      console.log(this.ids);
    },
    derive() {
      //导出
      location.href = `https://artxt.szart.cn/api/public/index.php/api/examenroll/export?
                page=${this.currentPage}&limit=${this.currentLimit}&dateQuery=${this.form.testDate}
                &search=${this.form.search}&org_id=${this.form.mechanism}`;
      // this.$request({
      //     // artxt.szart.cn
      //     url: '/api/examenroll/export',
      //     method: 'POST',
      //     data: {
      //         ids: this.ids
      //     }
      // }).then(res => {
      //     console.log(res)
      //     // if(res.code==1){
      //     //     this.$message({
      //     //         message: res.msg,
      //     //         type: 'success'
      //     //     });
      //     // }
      // })
    },
    print() {
      // console.log('打印预览')
      this.dialog = true;
      this.$request({
        url: "/api/examenroll/print",
        method: "POST",
        data: {
          id: this.id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let data = res.data;
          let printData = this.printData;
          printData.head_img = data.student.head_image; //头像
          printData.number = data.enroll_number; //编号
          printData.region = data.area; //所属地区
          printData.name = data.name; //姓名
          printData.sex = data.student.gender; //性别
          printData.pinyin = data.english_name; //拼音名
          printData.nationality = data.student.nation2; //国籍
          printData.major = data.course; //报考专业
          printData.original_grade = data.original_level; //原有级别
          printData.level = data.exam_level; //现报等级
          printData.address = data.student.address; //家庭住址
          printData.birthday = data.student.birthdate; //出生日期
          printData.phone = data.student.mobile1; //手机号码
          printData.idcard = data.idcard; //考试证件号
          printData.Zipcode = data.student.post_code; //邮编
          printData.specificDate = data.print_date; //日期
        }
      });
    },
    //排序
    abc(i) {
      console.log(i);
      this.tableData = [];
      this.arr = [];
      this.arr.push({
        title: i.prop,
        order: i.order,
      });
      console.log(this.arr);
      this.getList();
    },
    // 批量考试成绩
    batch() {
      this.dlog = true;
    },
    // 确认
    submitform1(form1) {
      this.$refs[form1].validate((valid) => {
        if (valid) {
          if (this.bool) {
            return;
          }
          this.bool = true;
          this.$request({
            url: "/api/examenroll/batchScore",
            method: "POST",
            data: {
              type: this.form1.adopts,
              ids: this.ids,
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.dlog = false;
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.dlog = false;
              }
            })
            .catch(() => {
              this.bool = false;
            });
        }
      });
    },
    // 取消
    resetForm1(form1) {
      this.dlog = false;
      this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.TestResult {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* 查询部分 */
.form {
  padding-top: 34px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}
.form .el-select {
  width: 276px;
  height: 37px;
}
/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

::v-deep .el-icon-plus {
  font-weight: bold !important;
}
/* 打印预览 */
.print {
  display: inline-block;
  padding-left: 10px;
  width: 94px;
  height: 30px;
  border-radius: 3px;
  vertical-align: middle;
  background: url(../../assets/print.png) 8px 9px no-repeat #18bc9c;
  border: none;
  color: #ffffff;
  margin: 0px 10px;
  cursor: pointer;
}
/* 导出按钮 */
.derive {
  display: inline-block;
  width: 113px;
  height: 30px;
  padding-left: 10px;
  vertical-align: middle;
  background: url(../../assets/export.png) 6px 6px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}
/* 更多 */
.more {
  display: inline-block;
  width: 66px;
  height: 30px;
  vertical-align: middle;
  background: url(../../assets/more.png) 6px 8px no-repeat #29726c;
  color: #ffffff;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
}
.batch {
  display: inline-block;
  width: 120px;
  height: 30px;
  vertical-align: middle;
  background: #18bc9c;
  color: #ffffff;
  border-radius: 3px;
  padding-left: 10px;
  border: none;
  cursor: pointer;
}
/* 表格 */
.el-table {
  margin: 20px;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 30px;
}
.checkBtn {
  color: #ffffff;
  background: #18bc9c;
  font-size: 12px;
  border-radius: 2px;
  /* border: none; */
}
.edit {
  background: #ff7300;
  color: #ffffff;
}
.txtbox {
  width: 230px;
  height: 19px;
  display: inline-block;
  margin-bottom: 18px;
  margin-right: 5px;
  font-size: 14px;
  color: #000000;
}
.itp {
  width: 120px;
  padding-left: 5px;
  border: 1px solid #ccc;
  height: 25px;
  outline: none;
  font-size: 14px;
  background-color: #ffffff;
}
/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
/* 打印弹出样式 */
.TestResult ::v-deep .el-dialog__body {
  padding: 30px 20px;
}

.TestResult ::v-deep .el-dialog__footer {
  padding: 10px 20px 20px !important;
  text-align: center;
}

#printTest {
  width: 800px;
  /* height: 500px; */
  /* border: 1px #ccc solid; */
  margin: 0px auto;
  color: #333;
}

.top {
  width: 100%;
  height: 80px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.top-logo {
  width: 350px;
  height: 100%;
}

.top-logo img {
  width: 100%;
  height: 100%;
}

.top-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
}

.sign_up {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
}

/* 打印预览表格 */
table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-weight: bold;
}

table td {
  height: 30px;
}

table td:first-child {
  width: 15%;
}

/* 相片 */
.td_1 img {
  width: 90px;
  height: 130px;
  border: 1px dashed #ccc;
  margin: auto;
}

.td_2 {
  width: 85px;
}

.td_3 {
  width: 130px;
}

table tr:last-child {
  height: 100px;
}

.remarks {
  font-size: 14px;
  font-weight: 500;
  font-family: Microsoft YaHei;
  color: #000000;
  text-align: left;
  padding-left: 10px;
}

/* 底部 */
.bottom {
  text-align: right;
}

.handle {
  margin: 20px 0px;
  font-size: 17px;
  font-weight: bold;
  color: #000000;
}

.handle input {
  border: none;
}

.bottom_text p {
  font-size: 15px;
  color: #000000;
  padding-right: 10px;
}

.phone_date {
  margin-top: 10px;
  color: #000000;
}

.phone_date span {
  display: inline-block;
}

.phone_date span:first-child {
  padding-right: 20px;
}

.phone_date span:last-child {
  padding: 0px 10px;
}

.form1 .el-select {
  width: 240px !important;
}
</style>
